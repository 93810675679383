<template>
  <div class="row">
    <div class="col-md-9">
      <div class="row">
        <div class="form-group col-sm-6">
          <label for="originalLocation" class="label-require">Original Location</label>

          <div class="input-group">
            <span class="input-group-text">
              <div
                class="spinner-border spinner-border-sm text-secondary"
                role="status"
                v-if="$data.ajax.originalLocationLoad"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <i class="fas fa-plane-departure text-danger" v-else></i>
            </span>
            <input
              type="text"
              class="form-control"
              id="originalLocation"
              placeholder="Original Location"
              autocomplete="off"
            />
          </div>
          <ErrorFormField :msgArray="$data.errorFields.originalLocation" />
          <input type="hidden" id="origin_lat" />
          <input type="hidden" id="origin_lon" />
          <input type="hidden" id="origin_location_country_code" />
          <input type="hidden" id="origin_postal_code" />
        </div>
        <div class="form-group col-sm-6">
          <label for="originalLocation" class="label-require">Destination Location</label>
          <div class="input-group">
            <span class="input-group-text">
              <LoadingIcons total="1" v-if="$data.ajax.destinationLocationLoad" />
              <i class="fas fa-plane-arrival text-danger" v-else></i>
            </span>
            <input
              type="text"
              class="form-control"
              id="destinationLocation"
              placeholder="Destination Location"
              autocomplete="off"
            />
          </div>
          <ErrorFormField :msgArray="$data.errorFields.desinationLocation" />
          <input type="hidden" id="destination_lat" />
          <input type="hidden" id="destination_lon" />
          <input type="hidden" id="destination_location_country_code" />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4 col-sm-6">
          <label class="control-label">Item category</label>
          <select class="form-select" id="item_category">
            <option v-for="item in $data.productCategories" :value="item.ID" :key="item.ID">
              {{ item.subcatname }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-4 col-sm-6">
          <label class="control-label label-require">Item weight</label>
          <div class="row g-1">
            <div class="col-sm-9">
              <input
                type="number"
                class="form-control"
                id="item_weight"
                placeholder="Item weight"
              />
            </div>
            <div class="col-sm-3">
              <select class="form-select" id="item_weight_unit">
                <option value="kg">kg</option>
                <option value="lbs">lbs</option>
              </select>
            </div>
          </div>
          <ErrorFormField :msgArray="$data.errorFields.itemWeight" />
        </div>
        <div class="form-group col-md-4 col-sm-6">
          <label class="control-label label-require">Item worth</label>
          <div class="input-group">
            <input
              type="number"
              class="form-control"
              placeholder="Item worth"
              aria-label="Item worth"
              id="item_worth"
            />
            <span class="input-group-text">{{ $store.state.currencySymbol }}</span>
          </div>
          <ErrorFormField :msgArray="$data.errorFields.itemWorth" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="form-group col-sm-12 text-end">
          <LoadingIcons total="3" v-if="$data.ajax.getRate" />
          <button class="btn btn-primary" id="getRatePeople" @click="getRate" v-else>
            Get rate
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="circle-price">
        <div class="fs-1 currency-symbol">{{ $store.state.currencySymbol }}</div>
        <div class="fs-4 currency-total" id="currencyTotal">{{ $data.currencyTotal }}</div>
        <div class="fs-1 currency-code" id="currencyCode">{{ $store.state.currencyCode }}</div>
      </div>

      <h4 class="text-primary" v-if="$data.minDelivery != ''">
        From <span class="text-secondary">{{ $data.minDelivery }}</span> to
        <span class="text-secondary">{{ $data.maxDelivery }}</span>
      </h4>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-9">
      <div
        class="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6 g-4"
        v-if="$data.couriers.length > 0"
      >
        <div class="col" v-for="(item, i) in $data.couriers" :key="i">
          <div class="card text-center h-100">
            <div class="card-body">
              <img
                :src="
                  'https://storage.googleapis.com/easyship-assets/website/courier-logos/color-img/' +
                  item.courier_logo +
                  '.png'
                "
                class="card-img-top w-50"
                :alt="item.courier_name"
                height="60"
              />
              <h5 class="card-title">{{ item.courier_name }}</h5>
              <p class="card-text">
                {{ item.min_delivery_time }} - {{ item.max_delivery_time }} workings day <br />
                <span
                  :class="{
                    'w-100 p-1 text-white': true,
                    'bg-danger': item.total_charge > $data.currencyTotal,
                    'bg-secondary': item.total_charge <= $data.currencyTotal,
                  }"
                >
                  {{ item.total_charge }} {{ $store.state.currencyCode }}
                </span>
                <br />
                <small
                  ><b
                    >{{ item.total_charge > $data.currencyTotal ? '+' : '-' }}
                    {{ Math.abs(item.total_charge - $data.currencyTotal).toFixed(2) }}
                    (~
                    {{
                      (
                        (Math.abs(item.total_charge - $data.currencyTotal) * 100) /
                        item.total_charge
                      ).toFixed(2)
                    }}
                    %
                    <span
                      :class="{
                        'text-danger': item.total_charge > $data.currencyTotal,
                        'text-secondary': item.total_charge <= $data.currencyTotal,
                      }"
                      >{{ item.total_charge > $data.currencyTotal ? 'more' : 'less' }}</span
                    >

                    )
                  </b></small
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card bg-dark text-white" v-if="$data.itemTax >= 0 && $data.itemDuty >= 0">
        <div class="card-header">Estimated tax & duty fees</div>
        <div class="card-body">
          <div>
            {{ $data.itemTax }}% Tax on your item value: <br />
            {{ taxValue }} {{ $store.state.currencyCode }}
          </div>
          <div>
            {{ $data.itemDuty }}% Duties on your item value: <br />
            {{ dutyValue }} {{ $store.state.currencyCode }}
          </div>
        </div>
        <div class="card-footer">Total : {{ taxDutyValue }} {{ $store.state.currencyCode }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import MyAutocomplete from '../../search/MyAutocomplete';
import LoadingIcons from '../../common/LoadingIcons.vue';
import ErrorFormField from '../../common/error/ErrorFormField.vue';

let autocomplete = require('autocompleter');
let countryArray = ['AU', 'CA', 'US'];
async function getPostalCodeFromLatlong(lat, lng) {
  let apiKey = process.env.VUE_APP_GOOGLEMAPS_KEY;
  let url =
    'https://maps.googleapis.com/maps/api/geocode/json?result_type=postal_code&latlng=' +
    lat +
    ',' +
    lng +
    '&key=' +
    apiKey;
  let postalCode = '';
  await fetch(url, {
    method: 'GET',
  })
    .then((response) => response.json())
    .then((resData) => {
      console.log('Success:', resData);
      // let messages = resData.messages
      // if (resData.status != 'ok') {
      //   alert('error while geting data ' + resData.message);
      //   return;
      // }
      // self.$data.countryList = resData.countryList;
      let ac = resData.results[0].address_components;

      if (ac[0].types[0] == 'postal_code') {
        postalCode = ac[0].long_name;
      }
    })
    .catch(function (err) {
      console.log('error postalcode', err);
    });
  // console.log('postalcode ', postalCode);
  return postalCode;
}
export default {
  name: 'Product',
  components: { LoadingIcons, ErrorFormField },
  data: function () {
    return {
      ajax: {
        originalLocationLoad: false,
        destinationLocationLoad: false,
        getRate: false,
      },
      errorFields: {
        originalLocation: [],
        desinationLocation: [],
        itemWeight: [],
        itemWorth: [],
      },
      currencyTotal: '',
      productCategories: [],
      couriers: [],
      itemTax: -2,
      itemDuty: -2,
      minDelivery: '',
      maxDelivery: '',
    };
  },
  created() {
    document.title = 'Trepr - Product | Service Rate Calculations | Flight Companion';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'Are you looking for a flight companion? Select the location & calculate the service rate for to parcel from your location to anywhere in the world. '
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr, Shopping Concierge, Buy products from abroad, Earn while travel, Travel and Earn'
      );
  },
  methods: {
    getRate: async function () {
      this.$data.errorFields.originalLocation = [];
      this.$data.errorFields.desinationLocation = [];
      this.$data.errorFields.itemWorth = [];
      this.$data.errorFields.itemWeight = [];

      let originalLocation = document.getElementById('originalLocation').value;
      let destinationLocation = document.getElementById('destinationLocation').value;
      let item_weight = document.getElementById('item_weight').value;
      let item_worth = document.getElementById('item_worth').value;
      let errorOriginal = [],
        errorDestinaion = [],
        errorItemWeight = [],
        errorItemWorth = [];
      if (originalLocation == '') {
        errorOriginal.push('This field is required.');
      }
      if (destinationLocation == '') {
        errorDestinaion.push('This field is required.');
      }
      if (item_weight == '') {
        errorItemWeight.push('This field is required.');
      }
      if (item_worth == '') {
        errorItemWorth.push('This field is required.');
      }
      if (
        errorOriginal.length > 0 ||
        errorDestinaion.length > 0 ||
        errorItemWeight.length > 0 ||
        errorItemWorth.length > 0
      ) {
        if (errorOriginal.length > 0) {
          this.$data.errorFields.originalLocation = errorOriginal;
        }
        if (errorDestinaion.length > 0) {
          this.$data.errorFields.desinationLocation = errorDestinaion;
        }
        if (errorItemWeight.length > 0) {
          this.$data.errorFields.itemWeight = errorItemWeight;
        }
        if (errorItemWorth.length > 0) {
          this.$data.errorFields.itemWorth = errorItemWorth;
        }

        return;
      }
      this.$data.ajax.getRate = true;
      let origin_lat = document.getElementById('origin_lat').value;

      let origin_lon = document.getElementById('origin_lon').value;
      let origin_location_country_code = document.getElementById(
        'origin_location_country_code'
      ).value;
      let origin_postal_code = document.getElementById('origin_postal_code').value;
      let destination_lat = document.getElementById('destination_lat').value;
      let destination_lon = document.getElementById('destination_lon').value;
      let destination_location_country_code = document.getElementById(
        'destination_location_country_code'
      ).value;
      let item_weight_unit = document.getElementById('item_weight_unit').value;
      let item_category = document.getElementById('item_category').value;
      // let currency = document.getElementById('selectCurrency');
      let currency_code = this.$store.state.currencyCode;
      let data = {
        origin_lat: origin_lat,
        origin_lon: origin_lon,
        origin_location_country_code: origin_location_country_code,
        destination_lat: destination_lat,
        destination_lon: destination_lon,
        destination_location_country_code: destination_location_country_code,
        item_weight: item_weight,
        item_weight_unit: item_weight_unit,
        item_worth: item_worth,
        item_category: item_category,
        currency_code: currency_code,
        // currencySymbol: currencySymbol,
      };
      console.log('datta sent ', data);
      let role = 'seeker';
      if (
        this.$store.state.currentRole != undefined &&
        this.$store.state.currentRole == 'traveller'
      ) {
        role = 'traveller';
      }

      data.role = role;

      // console.log('data ', data);
      // return;
      const formData = Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');

      let compareData = {
        origin_location_country_code: origin_location_country_code,
        destination_location_country_code: destination_location_country_code,
        origin_postal_code: origin_postal_code,
        item_weight: item_weight,
        item_weight_unit: item_weight_unit,
        item_worth: item_worth,
        currency_code: currency_code,
      };
      const formCompareData = Object.keys(compareData)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(compareData[key]))
        .join('&');

      // console.log('form data ', formData);
      let daysData = {
        origin_lat: origin_lat,
        origin_lon: origin_lon,
        destination_lat: destination_lat,
        destination_lon: destination_lon,
      };
      const formDaysData = Object.keys(daysData)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(daysData[key]))
        .join('&');
      let apiUrl = process.env.VUE_APP_API_URL;

      let self = this;

      let promiseServiceRate = fetch(apiUrl + 'price/get-service-rate-product', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: formData,
      }).then((response) => response.json());
      let promiseDaysData = fetch(apiUrl + 'price/get-estimated-time', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: formDaysData,
      }).then((response) => response.json());
      let promiseCompareRate = fetch(apiUrl + 'price/get-compare-rates', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: formCompareData,
      }).then((response) => response.json());
      var promiseArrayData = [promiseServiceRate, promiseCompareRate, promiseDaysData];
      if (destination_location_country_code != origin_location_country_code) {
        let itemData = {
          country_code: destination_location_country_code,
          item_category: item_category,
        };
        const formItemData = Object.keys(itemData)
          .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(itemData[key]))
          .join('&');
        let promiseItemData = fetch(apiUrl + 'price/get-item-tax-duty', {
          method: 'POST',
          headers: {
            // 'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
          },
          body: formItemData,
        }).then((response) => response.json());
        promiseArrayData.push(promiseItemData);
      }

      console.log('formDaysData', formDaysData);
      console.log('daysData', daysData);

      await Promise.all(promiseArrayData)
        .then((resData) => {
          console.log(resData);
          let messages = resData[0].messages;
          if (resData[0].status == 'error') {
            console.log('get data error for service rate product  ', messages);
            self.$data.errorFields.itemWorth = resData[0].money;
            self.$data.errorFields.itemWeight = resData[0].weight;
            self.$data.ajax.getRate = false;
            return false;
          }
          self.$data.currencyTotal = resData[0].convertedPrice;
          self.$data.couriers = resData[1].couriers;

          self.$data.minDelivery = resData[2].our_min_delivery_days;
          self.$data.maxDelivery = resData[2].our_max_delivery_days;

          if (destination_location_country_code != origin_location_country_code) {
            self.$data.itemTax = resData[3].itemTax;
            self.$data.itemDuty = resData[3].itemDuty;
          } else {
            self.$data.itemTax = 0;
            self.$data.itemDuty = 0;
          }
          self.$data.ajax.getRate = false;
        })
        .catch((err) => {
          console.error('Error:', err);
        });
    },
  },
  mounted() {
    let self = this;
    let inputOriginalLocation = document.getElementById('originalLocation');
    let inputDestinationLocation = document.getElementById('destinationLocation');
    // MyAutocomplete.initData(this);
    // this.$data.ajax.originalLocationLoad
    // let objCommonAutocomplete =
    autocomplete({
      input: inputOriginalLocation,
      minLength: 2,
      disableAutoSelect: true,
      fetch: (text, update) => MyAutocomplete.fetchAuto(text, update, self, 'original'),
      className: MyAutocomplete.classNameAuto(),
      render: (item, currentValue) => MyAutocomplete.renderAuto(item, currentValue, self),
      customize: (input, inputRect, container, maxHeight) =>
        MyAutocomplete.customizeAuto(input, inputRect, container, maxHeight, self, 'original'),
      onSelect: async function (item) {
        let tmpCoordinates = item.coordinates;
        document.getElementById('origin_lat').value = tmpCoordinates.lat;
        document.getElementById('origin_lon').value = tmpCoordinates.lon;
        document.getElementById('origin_location_country_code').value = item.country_code;
        inputOriginalLocation.value = '(' + item.code + ') ' + item.name;

        if (countryArray.includes(item.country_code)) {
          let tmp = await getPostalCodeFromLatlong(tmpCoordinates.lat, tmpCoordinates.lon);
          // console.log('return value postal code ', tmp);
          document.getElementById('origin_postal_code').value = tmp;
        } else {
          document.getElementById('origin_postal_code').value = '';
        }
      },
    });
    // let objCommonAutocomplete =
    autocomplete({
      input: inputDestinationLocation,
      minLength: 2,
      fetch: (text, update) => MyAutocomplete.fetchAuto(text, update, self, 'destination'),
      className: MyAutocomplete.classNameAuto(),
      render: (item, currentValue) => MyAutocomplete.renderAuto(item, currentValue, self),
      customize: (input, inputRect, container, maxHeight) =>
        MyAutocomplete.customizeAuto(input, inputRect, container, maxHeight, self, 'destination'),
      onSelect: function (item) {
        let tmpCoordinates = item.coordinates;
        inputDestinationLocation.value = '(' + item.code + ') ' + item.name;
        document.getElementById('destination_lat').value = tmpCoordinates.lat;
        document.getElementById('destination_lon').value = tmpCoordinates.lon;
        document.getElementById('destination_location_country_code').value = item.country_code;
      },
    });

    let apiUrl = process.env.VUE_APP_API_URL;
    fetch(apiUrl + 'common/get-product-category', {
      method: 'GET',
      // headers: headersObject,
    })
      .then((response) => response.json())
      .then((resData) => {
        // console.log('Success:', resData);
        // let messages = resData.messages
        if (resData.status == 'error') {
          alert('error while geting data for product category' + resData.message);
          return;
        }
        this.$data.productCategories = resData.productCategories;
      })
      .catch(function (err) {
        console.log('error', err);
      });
  },
  computed: {
    taxValue() {
      if (this.$data.itemTax >= 0) {
        let item_worth = document.getElementById('item_worth').value;
        let item_worth_number = parseFloat(item_worth);
        let tmp = (this.$data.itemTax * item_worth_number) / 100;
        return tmp.toFixed(2);
      }
      return -2;
    },
    dutyValue() {
      if (this.$data.itemDuty >= 0) {
        let item_worth = document.getElementById('item_worth').value;
        let item_worth_number = parseFloat(item_worth);
        let tmp = (this.$data.itemDuty * item_worth_number) / 100;
        return tmp.toFixed(2);
      }
      return -2;
    },
    taxDutyValue() {
      if (this.$data.itemDuty >= 0 && this.$data.itemTax >= 0) {
        let item_worth = document.getElementById('item_worth').value;
        let item_worth_number = parseFloat(item_worth);
        let tmp =
          (this.$data.itemDuty * item_worth_number + this.$data.itemTax * item_worth_number) / 100;
        return tmp.toFixed(2);
      }
      return -2;
    },
  },
};
</script>

<style scoped></style>
